body{
    background-color: #262626;
}

h1{
    font-family: 'Barlow', sans-serif;
    font-weight: 800;
    color: #ffffff;
    text-align:center;
}

img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 15vw;
}

.App{
    background-image:url("./img/Thugz-Mint-Background.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
    background-position: center ;
}

@font-face {
    font-family: 'Condensed Black Italic';
    src: local('Condensed Black Italic'), url(./fonts/DINPro-CondBlackIta.otf) format('woff');
}
@font-face {
    font-family: 'Din pro Condensed Bold Italic';
    src: local('Din pro Condensed Bold Italic'), url(./fonts/DINPro-CondBoldIta.otf) format('woff');
}
@font-face {
    font-family: 'Dinpro Condensed italic';
    src: local('Dinpro Condensed italic'), url(./fonts/DINPro-CondIta.otf) format('woff');
}

.gradient-text {
    background-image: linear-gradient(#ffb750,#f7881b);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Condensed Black Italic';
}

.gradient-border {
    background: linear-gradient(#ffb750,#f7881b);
}

.subtitle {
    font-family: 'Barlow', sans-serif;
    font-weight: normal;
    color: white;
    text-align:center;
    font-family: 'Dinpro Condensed italic';
}

.result-911 {
    font-family: 'Barlow', sans-serif;
    font-weight: 800;
    color: #c8c8c8;
    text-align:center;
    margin: 1rem;
}

.logo-main-title {
    display: block;
}

/* .thugz-list{

} */

.main-container{
    display: flex;
    width: 100%;
}
